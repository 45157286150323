import { z } from 'zod';
import { extendZodWithOpenApi } from '@asteasolutions/zod-to-openapi';
import { OrgaentityType } from '../../core-api/types/orgaentity.type';
import { translatableString } from '../../types/translatable-string.type';
import { ProductComponentType } from '../enums/product.enum';
extendZodWithOpenApi(z);
/** Regex that matches UUIDv4-based file names like "45564c7d-d296-4759-b4d7-4aefc6463f0a.jpg". */
export const imageFileNameRegex = new RegExp(/^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}.[a-z]{3,4}$/i);
const translatableMultipleImages = z.record(z.string().length(5).regex(new RegExp('^[a-z]{2}-[A-Z]{2}$')), z.array(z.string().regex(imageFileNameRegex).or(z.string().url().startsWith('https://'))
    .describe('Either an existing image file name (UUIDv4 + extension), or an external HTTPS-URL which will be used to download the new image to the shop.'))).openapi({
    examples: [{
            'en-US': [
                '45564c7d-d296-4759-b4d7-4aefc6463f0a.jpg',
                '9b5f4850-ddf5-4da8-96dd-ea6b9f69ed28.jpg',
            ],
            'de-DE': [
                '68e2f8c2-60fb-4c09-bf0e-fec2647b44db.jpg',
                'https://example.com/assets/file-to-be-copied.png', // Only for creating/updating products.
            ],
        }],
});
const productComponentIntroduction = z.object({
    id: z.string().min(1),
    type: z.literal(ProductComponentType.INTRODUCTION),
    introduction: z.object({
        images: translatableMultipleImages,
        text: translatableString,
    }),
}).openapi('ShopProductComponentIntroduction');
const productPriceModes = z.object({
    QUANTITY_MINIMUM: z.number().nonnegative().nullish().describe('Minimum amount that has to be ordered.'),
    QUANTITY_MAXIMUM: z.number().nonnegative().nullish().describe('Maximum amount that has to be ordered.'),
    PER_PRODUCT: z.string().nullish().describe('References the other product by name.'),
    PER_MONTH_UNTIL: z.literal('END_OF_YEAR').nullish(),
    GRADUATED_PRICE: z.object({
        products: z.array(z.string()),
        levels: z.array(z.object({
            quantity: z.number().int().nonnegative(),
            price: z.number().int().nonnegative(),
        })),
    }).nullish(),
    HOSTNAMES_DEPENDENT: z.boolean().nullish()
        .describe('Whether the product component depends on `extraData.hostnames`, e. g. `true` for license renewals or Windows 11 upgrades.'),
    QUANTITY_BASED_ON_DEVICES: z.array(z.object({
        type: z.nativeEnum(OrgaentityType),
        subtype: z.number().int().nonnegative().optional(),
    })).nullish(),
});
const productComponentProduct = z.object({
    id: z.string().min(1),
    type: z.literal(ProductComponentType.PRODUCT),
    product: z.object({
        name: z.string(),
        title: translatableString,
        description: translatableString,
        footnote: translatableString,
        price: z.object({
            net: z.string().regex(new RegExp(/^\d+$/)).describe('Net price of the product component in the subunit of the currency, e. g. 123,45 € → `"12345"` or $4567.89 → `"456789"`.'),
            modes: productPriceModes,
            taxName: translatableString,
            taxRate: z.number().nullable().describe('Tax rate, e. g. `0.19`'),
        }),
    }),
}).openapi('ShopProductComponentProduct');
export const productComponent = z.discriminatedUnion('type', [
    productComponentIntroduction,
    productComponentProduct,
]);
