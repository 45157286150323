import { z } from 'zod';
import { extendZodWithOpenApi } from '@asteasolutions/zod-to-openapi';
import { OrderStatus } from '../enums/order.enum';
extendZodWithOpenApi(z);
export var ProductIntegrationService;
(function (ProductIntegrationService) {
    ProductIntegrationService["DEP_CLIENT_RENEWAL"] = "DEP_CLIENT_RENEWAL";
    ProductIntegrationService["DEP_ORGAENTITY_DATA_SETTER"] = "DEP_ORGAENTITY_DATA_SETTER";
    ProductIntegrationService["BILLBEE_ORDER_CREATION"] = "BILLBEE_ORDER_CREATION";
    ProductIntegrationService["HANDOVER_EXPERIENCE_EMAIL_ATTACHMENT"] = "HANDOVER_EXPERIENCE_EMAIL_ATTACHMENT";
})(ProductIntegrationService || (ProductIntegrationService = {}));
export const productIntegrationBase = z.object({
    service: z.unknown(),
    triggerConditions: z.array(z.object({ status: z.nativeEnum(OrderStatus) })),
    options: z.record(z.string(), z.any()).optional(),
});
export const productIntegrationDepClientRenewal = productIntegrationBase
    .extend({
    service: z.enum([ProductIntegrationService.DEP_CLIENT_RENEWAL]),
    depClientRenewalOptions: z.object({
        renewUntilDate: z.string(),
    }),
});
export const productIntegrationDepOrgaentitiesDataSetter = productIntegrationBase
    .extend({
    service: z.enum([ProductIntegrationService.DEP_ORGAENTITY_DATA_SETTER]),
    options: z.object({
        orgaentitiesSource: z.enum(['order.extraData.clientnames', 'order.products.extraData.hostnames']).describe('Source of hostnames that will be updated'),
        targetObject: z.enum(['data', 'data_enhanced']),
        values: z.object({
            windows11MigrationOrderStatus: z.nativeEnum(OrderStatus).nullable(),
        }),
    }),
});
export const productIntegrationBillbeeOrderCreation = productIntegrationBase
    .extend({
    service: z.enum([ProductIntegrationService.BILLBEE_ORDER_CREATION]),
    options: z.object({
        billbeeCredentials: z.literal('default').or(z.string()).describe('Billbee credential key that is set in the Parameter Store object, e. g. "default"'),
        billbeeShopId: z.number().positive().optional().describe('Override Billbee shop ID'),
    }),
});
export const productIntegrationHandoverExperienceEmailAttachment = productIntegrationBase
    .extend({
    service: z.enum([ProductIntegrationService.HANDOVER_EXPERIENCE_EMAIL_ATTACHMENT]),
});
export const productIntegration = z.discriminatedUnion('service', [
    productIntegrationDepClientRenewal,
    productIntegrationDepOrgaentitiesDataSetter,
    productIntegrationBillbeeOrderCreation,
    productIntegrationHandoverExperienceEmailAttachment,
]);
export const productIntegrations = z.array(productIntegration);
