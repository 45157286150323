import { z } from 'zod';
import { criteriaList, limiter } from '../../core-api/types/provider-parameter.type';
import { stringToJsonSchema } from '../../utils/zod/string-to-json-schema';
import { OrderStatus } from '../enums/order.enum';
import { translatableString } from '../../types/translatable-string.type';
import { email } from '../../types/email.type';
import { imageFileNameRegex, productComponent } from './product-component.type';
import { productIntegration } from './product-integration.type';
export var ShippingAddressModes;
(function (ShippingAddressModes) {
    /** Orgaentity (LOCATION) address as Shipping address, address can be changed to other orgaentity */
    ShippingAddressModes["ORGAENTITY"] = "ORGAENTITY";
    /** Orgaentity (LOCATION) address as Shipping address, address can not be changed */
    ShippingAddressModes["ORGAENTITY_FIXED"] = "ORGAENTITY_FIXED";
    /** New Orgaentity (LOCATION) address is not possible to create */
    ShippingAddressModes["ORGAENTITY_EXISTING_ONLY"] = "ORGAENTITY_EXISTING_ONLY";
    /** User's billing contacts as dropdown options in Shipping address selection */
    ShippingAddressModes["CONTACT"] = "CONTACT";
    /** User's billing contacts as dropdown options in Shipping address selection and additional recipient name input */
    ShippingAddressModes["CONTACT_WITH_RECIPIENT"] = "CONTACT_WITH_RECIPIENT";
})(ShippingAddressModes || (ShippingAddressModes = {}));
const productSettings = z.object({
    basketCheckboxes: z.array(z.object({
        label: translatableString,
        required: z.boolean(),
    })),
    currency: z.string().length(3).openapi({ examples: ['EUR', 'USD'] }),
    customInputs: z.array(z.object({
        name: z.string().min(1).describe('Internal name of the input, e. g. `"costCenter"`.'),
        type: z.enum(['text', 'date']),
        label: translatableString,
        required: z.boolean(),
    })),
    customTexts: z.record(z.string().regex(new RegExp('^[A-Z._]*$')).openapi({ examples: ['SHOP.PRODUCTS.ADD_TO_BASKET', 'BASKET.BUY_VIA_INVOICE'] }), translatableString),
    renewsProduct: z.string().nullable(),
    shippingProductIdentifier: z.string().nullish(),
    shippingAddressMode: z.nativeEnum(ShippingAddressModes),
});
const productNotification = z.object({
    trigger: z.object({
        status: z.nativeEnum(OrderStatus),
    }),
    email: z.object({
        subject: z.record(z.string(), z.string()),
        body: z.record(z.string(), z.string()),
    }),
    additionalRecipients: z.object({
        emails: z.array(email).nullable(),
        emailsBcc: z.array(email).nullish(), // `undefined` allowed just for backwards-compatibility with products that existed before introducing the BCC feature.
    }),
});
const productAbstract = z.object({
    title: translatableString,
    description: translatableString,
    image: z.record(z.string().length(5).regex(new RegExp('^[a-z]{2}-[A-Z]{2}$')), z.string().regex(imageFileNameRegex).or(z.string().url())
        .describe('Either an existing image file name (UUIDv4 + extension), or an external URL which will be used to download the new image to the shop.')).openapi({
        examples: [{
                'en-US': '45564c7d-d296-4759-b4d7-4aefc6463f0a.jpg',
                'de-DE': 'https://example.com/assets/file-to-be-copied.png', // Only for creating/updating products.
            }],
    }),
    displayPrice: translatableString,
});
export const product = z.object({
    id: z.number().int().positive(),
    version: z.number().int().positive(),
    shopId: z.number().int().positive(),
    identifier: z.string().min(1).max(250),
    billingGroup: z.string().min(1).optional(),
    settings: productSettings,
    notifications: z.array(productNotification),
    abstract: productAbstract,
    components: z.array(productComponent),
    integrations: z.array(productIntegration),
    derivedFromId: z.number().int().positive().nullable(),
    derivedFromVersion: z.number().int().positive().nullable(),
    userId: z.number().int().positive().nullable(),
    created: z.string().datetime(),
    deleted: z.string().datetime().nullable(),
});
export const productCreateInput = product.omit({
    id: true,
    version: true,
    created: true,
    deleted: true,
}).extend({
    derivedFromId: product.shape.derivedFromId.optional(),
    derivedFromVersion: product.shape.derivedFromVersion.optional(),
});
export const productCreateDatabase = product.omit({
    version: true,
    deleted: true,
}).extend({
    derivedFromId: product.shape.derivedFromId.optional(),
    derivedFromVersion: product.shape.derivedFromVersion.optional(),
}).transform((obj, ctx) => {
    try {
        return {
            id: obj.id,
            shops_id: obj.shopId,
            identifier: obj.identifier,
            billing_group: obj.billingGroup,
            settings: JSON.stringify(obj.settings),
            notifications: JSON.stringify(obj.notifications),
            abstract: JSON.stringify(obj.abstract),
            components: JSON.stringify(obj.components),
            integrations: JSON.stringify(obj.integrations),
            derived_from_id: obj.derivedFromId,
            derived_from_version: obj.derivedFromVersion,
            users_id: obj.userId,
            created: obj.created,
        };
    }
    catch (e) {
        ctx.addIssue({ code: 'custom', message: 'Invalid JSON' });
        return z.NEVER;
    }
});
export const productPatchInput = product.omit({
    id: true,
    derivedFromId: true,
    derivedFromVersion: true,
    created: true,
    deleted: true,
}).partial();
export const productPatchDatabase = product.omit({
    deleted: true,
}).partial().extend({
    derivedFromId: product.shape.derivedFromId.optional(),
    derivedFromVersion: product.shape.derivedFromVersion.optional(),
}).transform((obj, ctx) => {
    try {
        return {
            id: obj.id,
            version: obj.version,
            shops_id: obj.shopId,
            identifier: obj.identifier,
            billing_group: obj.billingGroup,
            settings: JSON.stringify(obj.settings),
            notifications: JSON.stringify(obj.notifications),
            abstract: JSON.stringify(obj.abstract),
            components: JSON.stringify(obj.components),
            integrations: JSON.stringify(obj.integrations),
            derived_from_id: obj.derivedFromId,
            derived_from_version: obj.derivedFromVersion,
            users_id: obj.userId,
            created: obj.created,
        };
    }
    catch (e) {
        ctx.addIssue({ code: 'custom', message: 'Invalid JSON' });
        return z.NEVER;
    }
});
export const productInstancesListQueryParameters = z.object({
    deepResolveDepth: z.coerce.number().int().optional(),
    filter: stringToJsonSchema.pipe(criteriaList).optional(),
    limiter: stringToJsonSchema.pipe(limiter).optional(),
});
