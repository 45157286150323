import { z } from 'zod';
import { extendZodWithOpenApi } from '@asteasolutions/zod-to-openapi';
extendZodWithOpenApi(z);
export const criteria = z.object({
    field: z.string().describe('Database field.'),
    value: z.any().describe('Database record value.'),
    additionalOperator: z.string().optional().describe('Additional SQL operator (e. g. "like", "<", ">", etc).'),
    operator: z.string().optional().describe('Optional operator to override parental operator and strategy.'),
}).describe('Interface for reference identification.');
const criteriaListBase = z.object({
    strategy: z.enum(['or', 'and']),
    operator: z.enum(['where']),
});
export const criteriaList = criteriaListBase.extend({
    list: z.lazy(() => criteriaList.array().or(criteria.array())),
});
export const limiter = z.object({
    limit: z.coerce.number().int().positive().optional(),
    offset: z.coerce.number().int().nonnegative().optional(),
    groupBy: z.string().optional(),
});
export const sortingMultiple = z.array(z.object({
    column: z.string().regex(/^[0-9,a-z,A-Z$_,.]*$/).describe('Column name, e. g. "id" or "orgaentities.id".'),
    order: z.enum(['ASC', 'DESC']).optional(),
    nulls: z.string().optional(),
}));
